'use client';

import { ReactNode, useEffect, useMemo } from 'react';

import { io, Socket } from 'socket.io-client';

import { useAuth } from '@shared/common/providers/AuthProvider';
import { getProcessEnvGlobalConfig } from '@shared/config/global';

import { createSafeContext, useSafeContext } from '@utilities/context';

interface SocketProviderProps {
  children?: ReactNode;
}

interface ContextValue {
  socket?: Socket;
}

const Context = createSafeContext<ContextValue>();

export const SocketProvider = ({ children }: SocketProviderProps) => {
  const { isAuth } = useAuth();

  const value = useMemo(
    () => ({
      socket: io(getProcessEnvGlobalConfig('socketHost'), {
        path: '/ws/socket.io/',
        autoConnect: false,
        transports: ['websocket'],
        withCredentials: true,
        // extraHeaders: {
        // cookie: `Authorization=${Authorization}`,
        // authorization: `${Authorization}`,
        // Сookie: `Authorization=${Authorization}`,
        // Authorization: `${Authorization}`,
        // }
      }),
    }),
    [isAuth],
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useSocket = () => {
  const { socket } = useSafeContext(Context);
  const { isAuth } = useAuth();

  useEffect(() => {
    if (!isAuth || !socket || socket.connected) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.sock = socket;
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, [isAuth, socket]);

  return { socket };
};
