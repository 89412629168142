'use client';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';

import mixpanel from 'mixpanel-browser';

import { DeserializedUser } from '@api';

import { MIXPANEL_EVENT_NAMES, MixpanelEvent, MixpanelEventName } from '@shared/api/mixPanel';
import { useAuth } from '@shared/common/providers/AuthProvider';
import { getProcessEnvGlobalConfig } from '@shared/config/global';
import useMixPanelToggles from '@shared/hooks/useMixPanelToggles';

import { createSafeContext, useSafeContext } from '@utilities/context';

interface ContextValue {
  mixPanel: typeof mixpanel;
  track: CustomTrack;
  user?: DeserializedUser | null;
}

const Context = createSafeContext<ContextValue>();

export const useMixPanel = () => useSafeContext(Context);

const isDev = getProcessEnvGlobalConfig('isDev');
const mixPanelProjectToken = process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN || '';

type CustomTrack = <TEventName extends MixpanelEventName>(
  eventName: TEventName,
  properties?: MixpanelEvent[TEventName],
  optionsOrCallback?: Parameters<typeof mixpanel.track>[2],
  callback?: Parameters<typeof mixpanel.track>[3],
) => ReturnType<typeof mixpanel.track>;

interface MixPanelProviderProps {
  children?: ReactNode;
}

const eventsWithoutAuthorized: MixpanelEventName[] = [MIXPANEL_EVENT_NAMES.STORE_PAGE_VIEW];

export const MixPanelProvider = ({ children }: MixPanelProviderProps) => {
  const { user } = useAuth();

  const { getIsMixPanelEventEnabled } = useMixPanelToggles();

  useEffect(() => {
    mixpanel.init(mixPanelProjectToken, {
      debug: isDev,
      persistence: 'localStorage',
    });

    if (!user) {
      mixpanel.identify(mixpanel.get_distinct_id());
    } else {
      mixpanel.identify(String(user.id));
      mixpanel.people.set({
        $name: user.attributes.name,
        $email: user.attributes.email?.replace(/(.{2}).+(@.+)/, '$1***$2'),
        plan: user.membershipTier.attributes.key,
      });
    }
  }, [user]);

  const track = useCallback<CustomTrack>(
    (eventName, ...args) => {
      if (!user && !eventsWithoutAuthorized.includes(eventName)) {
        console.debug('Event not tracked: User is not authenticated or identified.');
        return;
      }

      if (!getIsMixPanelEventEnabled(eventName)) {
        console.debug(`Event not tracked: Tracking for event '${eventName}' is disabled.`);
        return;
      }

      try {
        mixpanel.track(eventName as string, ...args);
        console.debug(`Event tracked: Tracking '${eventName}'.`, { args });
      } catch (error) {
        console.debug(`Error tracking event '${eventName}':`, error);
      }
    },
    [user, getIsMixPanelEventEnabled],
  );

  const value = useMemo(() => {
    return {
      mixPanel: mixpanel,
      track,
      user,
    };
  }, [track, user]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useTrackMixPanel = (...args: Parameters<CustomTrack>) => {
  const { track } = useMixPanel();

  // Stringify the arguments to ensure stability
  const memoizedArgs = useMemo(() => JSON.stringify(args), [args]);

  useEffect(() => {
    if (args.length > 0) {
      // Deserialize the memoizedArgs back into their original form as a tuple
      const parsedArgs = JSON.parse(memoizedArgs) as Parameters<CustomTrack>;
      track(...parsedArgs);
    }
  }, [track, memoizedArgs]);
};
